import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getDefaultFeeType(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/master/get_default_fee_type', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getListFeeType(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/master/get_list_fee_type', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getFeeType(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/master/get_fee_type', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addFeeType(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/master/add_fee_type', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editFeeType(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/master/edit_fee_type', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteFeeType(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/master/delete_fee_type', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getFeeTypeOptions(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/master/fee_type_options', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
