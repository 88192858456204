<template>
  <div
    v-if="$can('read', 'ModuleMaster.FeeType')"
    style="height: inherit"
  >
    <b-overlay
      :show="showOverlay"
      rounded="sm"
    >
      <!-- Table Container Card -->
      <b-card
        no-body
      >

        <div class="m-2">

          <!-- Table Top -->
          <b-row>

            <!-- Per Page -->
            <b-col
              cols="12"
              sm="12"
              md="6"
            >
              <label>{{ $t('Entries') }}</label>
              <v-select
                v-model="perPage"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block ml-50 mr-1"
              />
            </b-col>

            <!-- Search -->
            <b-col
              cols="12"
              sm="12"
              md="6"
            >
              <div class="d-flex align-items-center justify-content-end w-100 mt-1 mt-md-0">
                <b-button
                  v-if="$can('create', 'ModuleMaster.FeeType')"
                  variant="primary"
                  class="w-50"
                  @click="addFeeTypeForm"
                >
                  {{ `${$t('Add')} ${$t('TitleMaster.FeeType')}` }}
                </b-button>
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1 ml-1"
                  :placeholder="`${$t('Search')}...`"
                />
              </div>
            </b-col>
          </b-row>

        </div>
        <b-table
          ref="refFeeTypeTable"
          :items="getListFeeType"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          hover
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
          class="position-relative"
          selectable
          select-mode="multi"
        >
          <!-- Column: Name -->
          <template #cell(name)="data">
            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.4rem 0.6rem 0.2rem 0.6rem; background-color: #eeeeee;"
                >
                  <span style="font-size: 14px; font-weight: bold;">
                    {{ $t('Name') }} »
                  </span>
                </b-list-group-item>
                <b-list-group-item
                  style="padding: 0.4rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="font-size: 16px;">{{ data.item.name }}</span>
                </b-list-group-item>
              </b-list-group>
            </div>
            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.4rem 0.6rem 0.2rem 0.6rem; background-color: #eeeeee;"
                >
                  <span style="font-size: 14px; font-weight: bold;">
                    {{ `${$t('FormFeeType.Name')} - ${$t('English')}` }} »
                  </span>
                </b-list-group-item>
                <b-list-group-item
                  style="padding: 0.4rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="font-size: 16px;">{{ data.item.name_en }}</span>
                </b-list-group-item>
              </b-list-group>
            </div>
            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.4rem 0.6rem 0.2rem 0.6rem; background-color: #eeeeee;"
                >
                  <span style="font-size: 14px; font-weight: bold;">
                    {{ `${$t('FormFeeType.Unit')}` }} »
                  </span>
                </b-list-group-item>
                <b-list-group-item
                  style="padding: 0.4rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="font-size: 16px;">{{ $t(`FeeTypeUnit.${data.item.unit.label}`) }}</span>
                </b-list-group-item>
              </b-list-group>
            </div>
          </template>

          <!-- Column: Sort -->
          <template #cell(sort)="data">
            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.6rem;">
                    {{ $t('Sort') }} »
                  </span>
                  <span style="font-size: 18px;">
                    <b-badge
                      pill
                      variant="light-success"
                      class="px-1 py-25"
                    >
                      {{ data.item.sort }}
                    </b-badge>
                  </span>
                </b-list-group-item>
              </b-list-group>
            </div>
            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.6rem;">
                    {{ $t('Code') }} »
                  </span>
                  <span style="font-size: 18px;">
                    <b-badge
                      pill
                      variant="light-secondary"
                    >
                      {{ data.item.code }}
                    </b-badge>
                  </span>
                </b-list-group-item>
              </b-list-group>
            </div>
            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.6rem;">
                    {{ $t('ID') }} »
                  </span>
                  <span style="font-size: 18px;">
                    <b-badge
                      pill
                      variant="light-info"
                    >
                      {{ data.item.id }}
                    </b-badge>
                  </span>
                </b-list-group-item>
              </b-list-group>
            </div>
          </template>

          <!-- Column: Status -->
          <template #cell(status)="data">
            <!-- Status -->
            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.4rem 0.6rem 0.2rem 0.6rem; background-color: #eeeeee;"
                >
                  <span style="margin-right: 0.6rem; font-weight: bold;">
                    {{ $t('Status') }} »
                  </span>
                </b-list-group-item>
                <b-list-group-item
                  style="padding: 0.6rem 0.6rem 0.4rem 0.6rem;"
                >
                  <span style="font-size: 18px;">
                    <b-badge
                      pill
                      :variant="`light-${isStatusVariant(data.item.status)}`"
                      class="text-capitalize"
                    >
                      {{ $t(isStatusToText(data.item.status)) }}
                    </b-badge>
                  </span>
                </b-list-group-item>
              </b-list-group>
            </div>
            <!-- Use with -->
            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.4rem 0.6rem 0.2rem 0.6rem; background-color: #eeeeee;"
                >
                  <span style="margin-right: 0.6rem; font-weight: bold;">
                    {{ $t('Use with') }} »
                  </span>
                </b-list-group-item>
                <b-list-group-item
                  style="padding: 0.6rem 0.6rem 0.4rem 0.6rem;"
                >
                  <span
                    v-if="data.item.is_booking === true"
                    style="font-size: 18px;"
                  >
                    <b-badge
                      pill
                      variant="light-warning"
                      class="px-1"
                    >
                      {{ (data.item.is_booking === true)? $t('Booking') : '' }}
                    </b-badge>
                  </span>
                  <span
                    v-if="data.item.is_renting === true"
                    style="font-size: 18px;"
                    class="ml-50"
                  >
                    <b-badge
                      pill
                      variant="light-info"
                      class="px-1"
                    >
                      {{ (data.item.is_renting === true)? $t('Renting') : '' }}
                    </b-badge>
                  </span>
                </b-list-group-item>
              </b-list-group>
            </div>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">

            <div class="text-nowrap">
              <a
                class="action-icon"
                @click.stop="editFeeTypeForm(data)"
              >
                <feather-icon
                  v-if="$can('write', 'ModuleMaster.FeeType')"
                  :id="`fee-type-row-${data.item.id}-edit-icon`"
                  icon="EditIcon"
                  class="cursor-pointer mr-1"
                  size="16"
                  @click="editFeeTypeForm(data)"
                />
                <b-tooltip
                  v-if="$can('write', 'ModuleMaster.FeeType')"
                  :title="`${$t('Edit')} ${$t('ModuleMaster.FeeType')}`"
                  :target="`fee-type-row-${data.item.id}-edit-icon`"
                  :delay="{ show: 2500, hide: 50 }"
                  placement="left"
                />
              </a>

              <a
                class="action-icon"
                @click.stop="viewFeeTypeForm(data)"
              >
                <feather-icon
                  v-if="$can('read', 'ModuleMaster.FeeType')"
                  :id="`fee-type-row-${data.item.id}-view-icon`"
                  icon="EyeIcon"
                  size="16"
                  class="cursor-pointer mr-1"
                  @click="viewFeeTypeForm(data)"
                />
                <b-tooltip
                  v-if="$can('read', 'ModuleMaster.FeeType')"
                  :title="`${$t('View')} ${$t('ModuleMaster.FeeType')}`"
                  :target="`fee-type-row-${data.item.id}-view-icon`"
                  :delay="{ show: 2500, hide: 50 }"
                  placement="left"
                />
              </a>

              <a
                class="action-icon"
                @click.stop="deleteFeeTypeForm(data)"
              >
                <feather-icon
                  v-if="$can('delete', 'ModuleMaster.FeeType')"
                  :id="`fee-type-row-${data.item.id}-delete-icon`"
                  icon="TrashIcon"
                  variant="light-success"
                  size="16"
                  class="cursor-pointer mr-1"
                  @click="deleteFeeTypeForm(data)"
                />
                <b-tooltip
                  v-if="$can('delete', 'ModuleMaster.FeeType')"
                  :title="`${$t('Delete')} ${$t('ModuleMaster.FeeType')}`"
                  :target="`fee-type-row-${data.item.id}-delete-icon`"
                  :delay="{ show: 2500, hide: 50 }"
                  placement="right"
                />
              </a>

            </div>
          </template>

        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">
                {{ $t('Showing {from} to {to} of {of} entries',
                      {from: dataMeta.from, to: dataMeta.to, of: dataMeta.of })
                }}
              </span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentPage"
                :total-rows="totalFeeTypes"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>

      <fee-type-form
        v-model="shallShowFeeTypeFormModal"
        :fee-type-data="feeTypeData"
        :type-fee-type-form="typeFeeTypeForm"
        @fee-type-form-update="updateFeeTypeForm"
        @discard-fee-type-form="discardFeeTypeForm"
      />
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput,
  BTable, BButton,
  BBadge, BPagination, BTooltip, BListGroup, BListGroupItem,
  BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  ref,
  onUnmounted,
} from '@vue/composition-api'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import { $themeConfig } from '@themeConfig'
import tableFeeType from './tableFeeType'
import storeFeeType from './storeFeeType'
import FeeTypeForm from './FeeTypeForm.vue'

export default {
  components: {
    FeeTypeForm,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BBadge,
    BPagination,
    BTooltip,
    BListGroup,
    BListGroupItem,
    BButton,
    BOverlay,

    vSelect,
    FeatherIcon,
  },
  data() {
    return {
      showOverlay: false,
      feeTypeData: {},
      dialog: false,
    }
  },
  mounted() {
    this.getDefaultFeeType()
    this.locale = this.$i18n.locale
  },
  methods: {
    getDefaultFeeType() {
      this.showOverlay = true
      store
        .dispatch('store-fee-type/getDefaultFeeType', { language: this.$i18n.locale })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.showOverlay = false
          this.feeTypeData = response.data
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response?.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.showOverlay = false
        })
    },
    addFeeTypeForm() {
      this.showOverlay = true
      store
        .dispatch('store-fee-type/getDefaultFeeType', { language: this.$i18n.locale })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.showOverlay = false
          this.feeTypeData = response.data
          this.typeFeeTypeForm = 'Add'
          this.shallShowFeeTypeFormModal = true
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response?.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.showOverlay = false
        })
    },
    editFeeTypeForm(data) {
      this.showOverlay = true
      store
        .dispatch('store-fee-type/getFeeType', { language: this.$i18n.locale, id: data.item.id })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.showOverlay = false
          this.feeTypeData = response.data
          this.typeFeeTypeForm = 'Edit'
          this.shallShowFeeTypeFormModal = true
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response?.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.showOverlay = false
        })
    },
    viewFeeTypeForm(data) {
      this.showOverlay = true
      store
        .dispatch('store-fee-type/getFeeType', { language: this.$i18n.locale, id: data.item.id })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.showOverlay = false
          this.feeTypeData = response.data
          this.typeFeeTypeForm = 'View'
          this.shallShowFeeTypeFormModal = true
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response?.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.showOverlay = false
        })
    },
    deleteFeeTypeForm(data) {
      this.$swal({
        title: this.$i18n.t('Are you sure?'),
        text: this.$i18n.t("You won't be able to revert this"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$i18n.t('Yes, delete it'),
        cancelButtonText: this.$i18n.t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value === true) {
          store.dispatch('store-fee-type/deleteFeeType', { id: data.item.id })
            .then(() => {
              const okMsg = this.$i18n.t('Process on successfully')
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: okMsg,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              },
              {
                position: 'bottom-right',
              })
              this.$swal({
                icon: 'success',
                title: this.$i18n.t('Deleted'),
                text: this.$i18n.t('Your data has been deleted'),
                timer: 3000,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.refetchData()
            })
            .catch(error => {
              const { response, message } = error
              if (response) {
                let errorMsg = this.$i18n.t(response?.data.error)
                if (errorMsg === '') {
                  errorMsg = this.$i18n.t(response?.data.message)
                }
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: errorMsg,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                },
                {
                  position: 'bottom-right',
                })
              } else if (message) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error?.message,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                },
                {
                  position: 'bottom-right',
                })
              }
            })
        }
      })
    },
    updateFeeTypeForm() {
      this.refetchData()
    },
    discardFeeTypeForm() {
      this.refetchData()
    },
  },
  setup() {
    const shallShowFeeTypeFormModal = ref(false)
    const typeFeeTypeForm = ref('')

    if (!store.hasModule('store-fee-type')) store.registerModule('store-fee-type', storeFeeType)
    onUnmounted(() => {
      if (store.hasModule('store-fee-type')) store.unregisterModule('store-fee-type')
    })

    const {
      getListFeeType,
      tableColumns,
      perPage,
      currentPage,
      totalFeeTypes,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      locale,
      refFeeTypeTable,
      refetchData,
      isStatusVariant,
      isStatusToText,
    } = tableFeeType()

    const filterList = JSON.parse(localStorage.getItem(`${$themeConfig.app.session}-filter-fee-type-list`))
    if (filterList !== null) {
      searchQuery.value = (filterList.searchQuery !== null && filterList.searchQuery !== undefined) ? filterList.searchQuery : ''
      perPage.value = (filterList.perPage !== null && filterList.perPage !== undefined) ? filterList.perPage : ''
      currentPage.value = (filterList.page !== null && filterList.page !== undefined) ? filterList.page : ''
      sortBy.value = (filterList.sortBy !== null && filterList.sortBy !== undefined) ? filterList.sortBy : ''
      isSortDirDesc.value = (filterList.sortDesc !== null && filterList.sortDesc !== undefined) ? filterList.sortDesc : ''
    }

    return {
      shallShowFeeTypeFormModal,
      typeFeeTypeForm,

      getListFeeType,
      tableColumns,
      perPage,
      currentPage,
      totalFeeTypes,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      locale,
      refFeeTypeTable,
      refetchData,
      isStatusVariant,
      isStatusToText,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.fee-type-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div{
    .b-overlay {
      .bg-light {
        background-color: $theme-dark-body-bg !important;
      }
    }
    .swal2-popup {
      background-color: $theme-dark-body-bg !important;
    }
  }
  .table.b-table > tbody .b-table-row-selected.table-active td {
    background-color: $theme-dark-table-active-bg;
  }
  .table.b-table.table-hover > tbody > tr.table-active:hover td,
  .table.b-table.table-hover > tbody > tr.table-active:hover th {
    background-image: linear-gradient($theme-dark-table-active-hover-bg, $theme-dark-table-active-hover-bg);
  }
}
.b-table-selectable{
  .feather{
    font-size: 1.3rem;
  }
}
.action-icon {
  :hover {
    color: #429dc8 !important;
  }
}
</style>
