<template>
  <b-modal
    id="form-fee-type"
    :visible="shallShowFeeTypeFormModal"
    :title="`${$t('Form')} ${$t('TitleMaster.FeeType')}`"
    footer-class="d-flex justify-content-between"
    size="lg"
    centered
    no-close-on-backdrop
    @change="(val) => $emit('update:shall-show-fee-type-form-modal', val)"
  >
    <!-- Modal Header -->
    <template #modal-header>
      <h5 class="modal-title">
        {{ `${$t(typeFeeTypeForm)} ${$t('TitleMaster.FeeType')}` }}
      </h5>
      <div class="modal-actions">
        <feather-icon
          icon="MinusIcon"
          class="cursor-pointer"
          @click="(busy === false) ? $emit('update:shall-show-fee-type-form-modal', false) : false"
        />
        <feather-icon
          icon="XIcon"
          class="ml-1 cursor-pointer"
          @click="(busy === false) ? onDiscard() : false"
        />
      </div>
    </template>

    <!-- Modal Footer -->
    <template #modal-footer>
      <!-- Footer: Left Content -->
      <div>
        <b-button
          v-if="(typeFeeTypeForm !== 'View')"
          ref="submit"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          right
          :disabled="busy"
          @click="onSubmit"
        >
          {{ (typeFeeTypeForm === 'Add' || typeFeeTypeForm === 'Edit') ? $t('Save Data') : $t('Delete Data') }}
        </b-button>
      </div>

      <!-- Footer: Right Content -->
      <div>
        <!-- Discard -->
        <feather-icon
          icon="XCircleIcon"
          size="17"
          class="ml-75 cursor-pointer"
          @click="(busy === false) ? onDiscard() : false"
        />
      </div>
    </template>

    <!-- Modal: Body -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <div>
        <b-form
          ref="refForm"
          class="position-relative"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <div>
            <b-card style="margin-bottom: 0.6rem;">
              <div>
                <b-row>
                  <!-- Field: Code -->
                  <b-col
                    cols="3"
                  >
                    <b-form-group
                      v-if="(typeFeeTypeForm === 'Add' || typeFeeTypeForm === 'Edit')"
                      :label="$t('FormFeeType.Code')"
                      label-for="fee-type-code"
                    >
                      <validation-provider
                        #default="validationContext"
                        :name="$t('FormFeeType.Code')"
                        rules="required"
                      >
                        <b-form-input
                          id="fee-type-code"
                          v-model="feeTypeData.code"
                          :state="getValidationState(validationContext)"
                          :disabled="busy"
                        />
                        <small class="text-danger">{{ validationContext.errors[0] }}</small>
                      </validation-provider>

                    </b-form-group>

                    <b-form-group
                      v-else
                      :label="$t('FormFeeType.Code')"
                      label-for="fee-type-code"
                    >
                      <span class="form-info-box">{{ feeTypeData.code }}</span>
                    </b-form-group>
                  </b-col>

                  <!-- Field: Unit -->
                  <b-col
                    cols="3"
                  >
                    <b-form-group
                      v-if="(typeFeeTypeForm === 'Add' || typeFeeTypeForm === 'Edit')"
                      :label="$t('FormFeeType.Unit')"
                      label-for="fee-type-unit"
                    >
                      <validation-provider
                        #default="validationContext"
                        :name="$t('FormFeeType.Unit')"
                        rules="required"
                      >
                        <v-select
                          v-model="feeTypeData.unit"
                          :options="feeTypeData.unit_options"
                          :reduce="val => val.value"
                          :clearable="false"
                          input-id="fee-type-unit"
                          :state="getValidationState(validationContext)"
                          :get-option-label="option => $t(`FeeTypeUnit.${option.label}`)"
                        />
                        <small class="text-danger">{{ validationContext.errors[0] }}</small>
                      </validation-provider>

                    </b-form-group>

                    <b-form-group
                      v-else
                      :label="$t('FormFeeType.Unit')"
                      label-for="fee-type-unit"
                    >
                      <span class="form-info-box">{{ $t(`FeeTypeUnit.${feeTypeData.unit}`) }}</span>
                    </b-form-group>
                  </b-col>

                  <!-- Field: Use with - is_booking, is_renting -->
                  <b-col
                    cols="6"
                  >
                    <b-form-group
                      v-if="(typeFeeTypeForm === 'Add' || typeFeeTypeForm === 'Edit')"
                      :label="$t('FormFeeType.UseWith')"
                      label-for="fee-type-use-with"
                    >
                      <validation-provider
                        #default="validationContext"
                        :name="$t('FormFeeType.UseWith')"
                        rules="required"
                      >
                        <div class="d-flex">
                          <b-form-checkbox
                            v-model="feeTypeData.is_booking"
                            class="custom-control-warning"
                            name="check-button"
                            switch
                            inline
                            style="margin-top: 0.5rem; padding: 0rem 0rem 0.2rem 0rem;"
                          >
                            <span class="margin-top: 1rem; padding: 0rem 0rem 0.2rem 0rem;">
                              {{ $t('FormFeeType.IsBooking') }}
                            </span>
                          </b-form-checkbox>
                          <b-form-checkbox
                            v-model="feeTypeData.is_renting"
                            class="custom-control-info"
                            name="check-button"
                            switch
                            style="margin-right: 1rem; margin-top: 0.5rem; padding: 0rem 0rem 0.2rem 0rem;"
                          >
                            <span class="margin-top: 1rem; padding: 0rem 0rem 0.2rem 0rem;">
                              {{ $t('FormFeeType.IsRenting') }}
                            </span>
                          </b-form-checkbox>
                        </div>
                        <small class="text-danger">{{ validationContext.errors[0] }}</small>
                      </validation-provider>

                    </b-form-group>

                    <b-form-group
                      v-else
                      :label="$t('FormFeeType.UseWith')"
                      label-for="fee-type-use-with"
                    >
                      <span class="form-info-box">
                        <span
                          v-if="feeTypeData.is_booking === true"
                          style="font-size: 18px;"
                        >
                          <b-badge
                            pill
                            variant="light-warning"
                            class="px-1"
                          >
                            {{ (feeTypeData.is_booking === true)? $t('Booking') : '' }}
                          </b-badge>
                        </span>
                        <span
                          v-if="feeTypeData.is_renting === true"
                          style="font-size: 18px;"
                          class="ml-50"
                        >
                          <b-badge
                            pill
                            variant="light-info"
                            class="px-1"
                          >
                            {{ (feeTypeData.is_renting === true)? $t('Renting') : '' }}
                          </b-badge>
                        </span>
                      </span>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <!-- Field: Name -->
                  <b-col
                    cols="6"
                  >
                    <b-form-group
                      v-if="(typeFeeTypeForm === 'Add' || typeFeeTypeForm === 'Edit')"
                      :label="$t('FormFeeType.Name')"
                      label-for="fee-type-name"
                    >
                      <validation-provider
                        #default="validationContext"
                        :name="$t('FormFeeType.Name')"
                        rules="required"
                      >
                        <b-form-input
                          id="fee-type-name"
                          v-model="feeTypeData.name"
                          :state="getValidationState(validationContext)"
                          :disabled="busy"
                        />
                        <small class="text-danger">{{ validationContext.errors[0] }}</small>
                      </validation-provider>

                    </b-form-group>

                    <b-form-group
                      v-else
                      :label="$t('FormFeeType.Name')"
                      label-for="fee-type-name"
                    >
                      <span class="form-info-box">{{ feeTypeData.name }}</span>
                    </b-form-group>
                  </b-col>

                  <!-- Field: Name - English -->
                  <b-col
                    cols="6"
                  >
                    <b-form-group
                      v-if="(typeFeeTypeForm === 'Add' || typeFeeTypeForm === 'Edit')"
                      :label="`${$t('FormFeeType.Name')} - ${$t('English')}`"
                      label-for="fee-type-name-en"
                    >
                      <validation-provider
                        #default="validationContext"
                        :name="`${$t('FormFeeType.Name')} - ${$t('English')}`"
                        rules="required"
                      >
                        <b-form-input
                          id="fee-type-name-en"
                          v-model="feeTypeData.name_en"
                          :state="getValidationState(validationContext)"
                          :disabled="busy"
                        />
                        <small class="text-danger">{{ validationContext.errors[0] }}</small>
                      </validation-provider>

                    </b-form-group>

                    <b-form-group
                      v-else
                      :label="`${$t('FormFeeType.Name')} - ${$t('English')}`"
                      label-for="fee-type-name-en"
                    >
                      <span class="form-info-box">{{ feeTypeData.name_en }}</span>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <!-- Field: Min Renting -->
                  <b-col
                    cols="6"
                  >
                    <b-form-group
                      v-if="(typeFeeTypeForm === 'Add' || typeFeeTypeForm === 'Edit')"
                      :label="$t('FormProductType.Min Renting')"
                      label-for="fee-type-min-renting"
                    >
                      <validation-provider
                        #default="validationContext"
                        :name="$t('FormProductType.Min Renting')"
                        rules="required"
                      >
                        <b-form-input
                          id="fee-type-min-renting"
                          v-model="feeTypeData.min_renting"
                          :state="getValidationState(validationContext)"
                          :disabled="busy"
                          @keypress="IsNumber"
                        />
                        <small class="text-danger">{{ validationContext.errors[0] }}</small>
                      </validation-provider>

                    </b-form-group>

                    <b-form-group
                      v-else
                      :label="$t('FormProductType.Min Renting')"
                      label-for="fee-type-min-renting"
                    >
                      <span class="form-info-box">{{ feeTypeData.min_renting }}</span>
                    </b-form-group>
                  </b-col>

                  <!-- Field: Max Renting -->
                  <b-col
                    cols="6"
                  >
                    <b-form-group
                      v-if="(typeFeeTypeForm === 'Add' || typeFeeTypeForm === 'Edit')"
                      :label="$t('FormProductType.Max Renting')"
                      label-for="fee-type-max-renting"
                    >
                      <validation-provider
                        #default="validationContext"
                        :name="$t('FormProductType.Max Renting')"
                        rules="required"
                      >
                        <b-form-input
                          id="fee-type-max-renting"
                          v-model="feeTypeData.max_renting"
                          :state="getValidationState(validationContext)"
                          :disabled="busy"
                          @keypress="IsNumber"
                        />
                        <small class="text-danger">{{ validationContext.errors[0] }}</small>
                      </validation-provider>

                    </b-form-group>

                    <b-form-group
                      v-else
                      :label="$t('FormProductType.Max Renting')"
                      label-for="fee-type-max-renting"
                    >
                      <span class="form-info-box">{{ feeTypeData.max_renting }}</span>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <!-- Field: Status -->
                  <b-col
                    cols="5"
                  >
                    <b-form-group
                      v-if="(typeFeeTypeForm === 'Add' || typeFeeTypeForm === 'Edit')"
                      :label="$t('Status')"
                      label-for="fee-type-status"
                    >
                      <validation-provider
                        #default="validationContext"
                        :name="$t('Status')"
                        rules="required"
                      >
                        <b-form-radio
                          v-model="feeTypeData.status"
                          name="fee-type-status"
                          value="active"
                          class="custom-control-success cursor-pointer"
                          inline
                        >
                          <span class="cursor-pointer">{{ $t('Active') }}</span>
                        </b-form-radio>
                        <b-form-radio
                          v-model="feeTypeData.status"
                          name="fee-type-status"
                          value="inactive"
                          class="custom-control-danger cursor-pointer"
                          inline
                        >
                          <span class="cursor-pointer">{{ $t('Inactive') }}</span>
                        </b-form-radio>
                        <small class="text-danger">{{ validationContext.errors[0] }}</small>
                      </validation-provider>

                    </b-form-group>

                    <b-form-group
                      v-else
                      :label="$t('Status')"
                      label-for="fee-type-status"
                    >
                      <span
                        class="form-info-box"
                        style="font-size: 18px;"
                      >
                        <b-badge
                          pill
                          :variant="`light-${isStatusVariant(feeTypeData.status)}`"
                          class="px-1 py-50"
                        >
                          {{ $t(textFirstUpper(feeTypeData.status)) }}
                        </b-badge>
                      </span>
                    </b-form-group>
                  </b-col>

                  <!-- Field: Sort -->
                  <b-col
                    cols="7"
                  >
                    <b-form-group
                      v-if="(typeFeeTypeForm === 'Add' || typeFeeTypeForm === 'Edit')"
                      :label="$t('Sort')"
                      label-for="fee-type-sort"
                    >
                      <validation-provider
                        #default="validationContext"
                        :name="$t('Sort')"
                        rules="required"
                      >
                        <b-row>
                          <b-col cols="3">
                            <b-form-radio
                              v-model="feeTypeData.sort_type"
                              name="fee-type-sort"
                              value="input"
                              class="custom-control-info cursor-pointer"
                              inline
                            >
                              <span class="cursor-pointer">{{ $t('Input') }}</span>
                            </b-form-radio>
                          </b-col>
                          <b-col cols="3">
                            <b-form-input
                              id="fee-type-sort"
                              v-model="feeTypeData.sort"
                              :state="getValidationState(validationContext)"
                              :disabled="!(feeTypeData.sort_type === 'input')"
                              class="mt-n1"
                            />
                          </b-col>
                          <b-col
                            v-if="typeFeeTypeForm === 'Add'"
                            cols="3"
                          >
                            <b-form-radio
                              v-model="feeTypeData.sort_type"
                              name="fee-type-sort"
                              value="first"
                              class="custom-control-info cursor-pointer"
                              inline
                            >
                              <span class="cursor-pointer">{{ $t('First') }}</span>
                            </b-form-radio>
                          </b-col>
                          <b-col
                            v-if="typeFeeTypeForm === 'Add'"
                            cols="3"
                          >
                            <b-form-radio
                              v-model="feeTypeData.sort_type"
                              name="fee-type-sort"
                              value="last"
                              class="custom-control-info cursor-pointer"
                              inline
                            >
                              <span class="cursor-pointer">{{ $t('Last') }}</span>
                            </b-form-radio>
                          </b-col>
                        </b-row>
                        <small class="text-danger">{{ validationContext.errors[0] }}</small>
                      </validation-provider>

                    </b-form-group>

                    <b-form-group
                      v-else
                      :label="$t('Sort')"
                      label-for="fee-type-sort"
                    >
                      <span
                        class="form-info-box"
                        style="font-size: 18px;"
                      >
                        <b-badge
                          pill
                          variant="light-success"
                          class="px-1 py-50"
                        >
                          {{ feeTypeData.sort }}
                        </b-badge>
                      </span>
                    </b-form-group>
                  </b-col>
                </b-row>

              </div>
            </b-card>
          </div>

        </b-form>

      </div>

    </validation-observer>

    <b-overlay
      :show="busy"
      no-wrap
      @shown="onShown"
      @hidden="onHidden"
    >
      <template #overlay>
        <div
          v-if="processing"
          class="text-center p-2 bg-primary text-light rounded"
        >
          <feather-icon
            icon="UploadCloudIcon"
            size="20"
          />
          <div class="mb-2">
            {{ `${$t('Processing')}...` }}
          </div>
          <b-progress
            min="1"
            max="10"
            :value="counter"
            variant="success"
            height="6px"
            class="mx-n1"
          />
        </div>
        <div
          v-else
          ref="dialog"
          tabindex="-1"
          role="dialog"
          aria-modal="false"
          aria-labelledby="form-confirm-label"
          class="text-center p-3"
        >
          <b-card-text
            class="font-weight-bolder"
          >
            {{ $t('Are you sure?') }}
          </b-card-text>
          <div class="d-flex">
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              class="mr-3"
              @click="onCancel"
            >
              {{ $t('Cancel') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="outline-success"
              @click="onOK"
            >
              {{ $t('Ok') }}
            </b-button>
          </div>
        </div>
      </template>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BButton, BForm,
  BFormGroup,
  BRow,
  BCol,
  BCard,
  BOverlay,
  BProgress,
  BCardText,
  BFormInput,
  BFormRadio,
  BBadge,
  BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@validations'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import vSelect from 'vue-select'
import tableFeeType from './tableFeeType'

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BButton,
    BForm,
    BFormGroup,
    BOverlay,
    BRow,
    BCol,
    BCard,
    BProgress,
    BCardText,
    BFormInput,
    BFormRadio,
    BBadge,
    BFormCheckbox,

    ValidationProvider,
    ValidationObserver,
    FeatherIcon,
    vSelect,
  },
  model: {
    prop: 'shallShowFeeTypeFormModal',
    event: 'update:shall-show-fee-type-form-modal',
  },
  props: {
    shallShowFeeTypeFormModal: {
      type: Boolean,
      required: true,
    },
    typeFeeTypeForm: {
      type: String,
      default: 'Add',
    },
    feeTypeData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      required,
      busy: false,
      processing: false,
      counter: 1,
      interval: null,
    }
  },
  methods: {
    onShown() {
      // Focus the dialog prompt
      this.$refs.dialog.focus()
    },
    onHidden() {
      // In this case, we return focus to the submit button
      // You may need to alter this based on your application requirements
      this.$refs.submit.focus()
    },
    onSubmit() {
      this.$refs.refFormObserver.validate().then(success => {
        if (success) {
          this.processing = false
          this.busy = true
        }
      })
    },
    onCancel() {
      this.busy = false
    },
    onOK() {
      this.processing = true
      if (this.typeFeeTypeForm === 'Add') {
        if (this.feeTypeData.sort_type !== 'input') {
          this.feeTypeData.sort = ''
        }
        store.dispatch('store-fee-type/addFeeType', this.feeTypeData)
          .then(() => {
            const okMsg = this.$i18n.t('Process on successfully')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: okMsg,
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            })
            this.busy = false
            this.processing = false

            this.$swal({
              icon: 'success',
              title: this.$i18n.t('Added'),
              text: this.$i18n.t('Your data has been added'),
              timer: 3000,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })

            this.$emit('fee-type-form-update')
            this.$emit('update:shall-show-fee-type-form-modal', false)
          })
          .catch(error => {
            const { response, message } = error
            if (response) {
              const errorMsg = this.$i18n.t(response?.data.error)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorMsg,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            } else if (message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error?.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            }
            this.busy = false
            this.processing = false
          })
      } else if (this.typeFeeTypeForm === 'Edit') {
        store.dispatch('store-fee-type/editFeeType', { id: this.feeTypeData.id, data: this.feeTypeData })
          .then(() => {
            const okMsg = this.$i18n.t('Process on successfully')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: okMsg,
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            })
            this.busy = false
            this.processing = false

            this.$emit('fee-type-form-update')
            this.$emit('update:shall-show-fee-type-form-modal', false)
          })
          .catch(error => {
            const { response, message } = error
            if (response) {
              const errorMsg = this.$i18n.t(response?.data.error)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorMsg,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            } else if (message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error?.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            }
            this.busy = false
            this.processing = false
          })
      } else if (this.typeFeeTypeForm === 'Delete') {
        store.dispatch('store-fee-type/deleteFeeType', {
          id: this.feeTypeData.id,
        })
          .then(() => {
            const okMsg = this.$i18n.t('Process on successfully')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: okMsg,
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            })
            this.busy = false
            this.processing = false

            this.$emit('fee-type-form-update')
            this.$emit('update:shall-show-fee-type-form-modal', false)
          })
          .catch(error => {
            const { response, message } = error
            if (response) {
              const errorMsg = this.$i18n.t(response?.data.error)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorMsg,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            } else if (message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error?.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            }
            this.busy = false
            this.processing = false
          })
      }
    },
    onDiscard() {
      this.$emit('discard-fee-type-form')
      this.$emit('update:shall-show-fee-type-form-modal', false)
    },
    IsNumber(event) {
      if (!/\d/.test(event.key)) return event.preventDefault()
      return null
    },
  },
  setup() {
    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation()

    const {
      textFirstUpper,
      isStatusVariant,
      isStatusToText,
      typeToText,
    } = tableFeeType()

    return {
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,

      textFirstUpper,
      isStatusVariant,
      isStatusToText,
      typeToText,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/_variables';
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.modal{
  .line-height-info{
    padding-bottom: 0.6rem;
    &.end{
      padding-bottom: 0;
    }
  }
  .modal-header,
  .modal-header[class*='bg-'] {
    background-color: #fff !important;
  }
  .modal-body {
    background-color: $body-bg !important;
  }
}
.form-info-box{
  display: flex;
  padding: 0.6rem 1rem;
  background-color: #f8f8f8;
  background-clip: padding-box;
  border: 1px solid #f0f0f0;
  border-radius: 0.357rem;
}

.dark-layout {
  .modal{
    .line-height-info{
      padding-bottom: 0.6rem;
      &.end{
        padding-bottom: 0;
      }
    }
    .modal-header,
    .modal-header[class*='bg-'] {
      background-color: $theme-dark-body-bg !important;
    }
    .modal-body {
      background-color: $theme-dark-table-active-bg !important;
    }
  }
  .form-info-box{
    display: flex;
    padding: 0.6rem 1rem;
    background-color: $theme-dark-input-bg;
    background-clip: padding-box;
    border: 1px solid $theme-dark-border-color;
    border-radius: 0.357rem;
  }
}
</style>
